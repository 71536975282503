angular
    .module('annexaApp')
    .component('annexaBoxObjectThirdsReceiver',{
    	templateUrl: './components/thirds/annexa-box-object-third-receiver/annexa-box-object-third-receiver.html',
    	controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'AnnexaFormlyFactory', 'TerritoryFactory', 'AnnexaPermissionsFactory', 'globalModals', 'DialogsFactory', 'ThirdFactory', '$http', 'RestService', 'TableFilter','DTColumnBuilder', 'AnnexaModalFactory', 'dialogs', 'ErrorFactory',
    		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, AnnexaFormlyFactory, TerritoryFactory, AnnexaPermissionsFactory, globalModals, DialogsFactory, ThirdFactory, $http, RestService, TableFilter, DTColumnBuilder, AnnexaModalFactory, dialogs, ErrorFactory) {
    		var vm = this;
    		vm.languageColumn = undefined;
			vm.ReceiverBox = undefined;
			
			vm.deleteReceiver = function(receiverId){
				var titleDeleteMessage = '';
	        	var confirmDeleteMessage = '';
	        	
	        	titleDeleteMessage = 'global.literals.delete';
	        	confirmDeleteMessage = 'global.literals.deleteReceiverBox';
	        	
	        	DialogsFactory.confirm(titleDeleteMessage, confirmDeleteMessage)
	                .then(function (data) {
	                	ThirdFactory.deleteRepresentation(vm.third.id, receiverId).then(function(data) {
	                  	  if(vm.receiverTableDefinition && vm.receiverTableDefinition.reloadInternalData) {
	      						vm.receiverTableDefinition.reloadInternalData(true, true);
								ThirdFactory.calculateCounters('thirdReceivers');
	      					}
	                    }).catch(function(error) {
	                    	if(error){
				      			vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
				      		}else{
				      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
				      		}
	                    });
	                }).catch(function (data) {
	                    //Empty
	            });
				
			};
			vm.addRepresentation = function(relatedThird){
				var modal = angular.copy(globalModals.representationAdd);
				ThirdFactory.getRelatedThirdRepresentations(vm.third.id).then(function (data){
					var addReceiverSave = function(receiver){
						var self = this;
						var modal_data = this.annexaFormly.model.modal_body;
	                    var objThird = JSOG.decode(modal_data.object);
	                    var third = JSOG.decode(modal_data.third);
	                    var newContent = undefined;
	                    if(modal_data.representationType && modal_data.representationType == 'representat'){
	                    	newContent = {
	                    			agent: third,
	                    			represented: objThird,
	                    			startValidityPeriod: modal_data.startValidityPeriod,
	                    			endValidityPeriod: modal_data.endValidityPeriod,
	                    			revoked: false
	                    	} 
	                	}else{
	                		newContent = {
	                				agent: objThird,
	                				represented: third,
	                				startValidityPeriod: modal_data.startValidityPeriod,
	                				endValidityPeriod: modal_data.endValidityPeriod,
	                				revoked: false
	                		}
	                	}
	                    ThirdFactory.createRepresentation(vm.third.id, newContent).then(function(data){
		               		 if(vm.receiverTableDefinition && vm.receiverTableDefinition.reloadInternalData) {
		      						vm.receiverTableDefinition.reloadInternalData(true, true);
		      						self.close();
		      						ThirdFactory.calculateCounters('thirdReceivers');
		      					}
		           		}).catch(function(error){
		           			if(error){
				      			vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
				      		}else{
				      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
				      		}
		           		});
					};
					modal.size = 'modal-md';
	                modal.annexaFormly.model = {};
	                modal.annexaFormly.model.modal_body = { third: vm.third, startValidityPeriod: undefined, endValidityPeriod: undefined, object: relatedThird[0] };
	                modal.annexaFormly.options = {};
	                modal.annexaFormly.options.formState = {readOnly: false, data: ((data)?JSOG.decode(data):[])};
	                AnnexaFormlyFactory.showModal("modalNewContact", modal, addReceiverSave, false);
				}).catch(function (error) {
					if(error){
		      			vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
		      		}else{
		      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
		      		}
				});
			};
			
			var generateReceiverBox= function(){
				
				if(!vm.receiverTableDefinition){
    				vm.receiverTableDefinition = {};
    			}
		        
				var isAgent = function(object){
                	if(object && object.represented && object.represented.id && object.agent && object.agent.id){
                		if(vm.third && vm.third.id && vm.third.id == object.agent.id){
                			return false;
                			
                		}
                	}else if(object && object.represented && object.represented.id){
                		return false;
                	}
                	return true;
                }
                
				var receiverTypeRender = function(data, type, full, meta) {
    				var content = '';
    				if(isAgent(full)){
    					content += '<i title="{{\'global.literals.representant\' | translate}}" class="fa fa-address-book-o" aria-hidden="true"></i>';
    	            }else{
    					content += '<i title="{{\'global.literals.representat\' | translate}}" class="fa fa-address-book" aria-hidden="true"></i>';
    				}
    				return content;
    			}
    			var actionsTitle = function () {
    	            return '<div class="column-actions"></div>';
    	        }
                var actionsRender = function (data, type, full, meta) {
    				var content = '';
    				if(full && full.id){
    					content += '<button type="button"  class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.deleteReceiver('+full.id+')" permission permission-only="[\'edit_third\']">';
    	                content += '<i class="fa fa-remove" title="{{ \'global.literals.remove\' | translate }}" aria-hidden="true"></i>';
    	                content += '<span class="sr-only">{{ \'global.literals.remove\' | translate}}</span>';
    	                content += '</button>';
    				}
                    return content;
                }
                
                var receiverNameRender = function(data, type, full, meta) {
    				var content = '';
    				if(isAgent(full)) {
    					content += '<span class="_600">'+full.agent.completeName+'</span>';
    				}else{
    					content += '<span class="_600">'+full.represented.completeName+'</span>';
    				}
                    return content;
    			}
                
                var receiverIdDocumentRender = function(data, type, full, meta) {
    				var content = '';
    				if(isAgent(full)) {
    					content += '<href-permission class-href="text-primary _600"  link-href="annexa.thirds.edit" attr-compare="id" id-href="'+full.agent.id+'" name-href="tableObjects[' + full.id + '].agent.identificationDocument" href-permission-only="\'view_thirds\'"/>';
    				}else{
    					content += '<href-permission class-href="text-primary _600"  link-href="annexa.thirds.edit" attr-compare="id" id-href="'+full.represented.id+'" name-href="tableObjects[' + full.id + '].represented.identificationDocument" href-permission-only="\'view_thirds\'"/>';
    				}
                    return content;
    			}
                
                var receiverStateRender = function(data, type, full, meta) {
    				var content = '';
    				var isValid = ((full && full.endValidityPeriod && full.endValidityPeriod < new Date())? false : true);
    				if(!isValid && !full.revoked) {
    					content += '<span class="label show danger" translate="global.literals.expired">Caducada</span> ';
    					content += '<span class="_600" translate="global.literals.the">el</span> ';
    				}else if (!isValid && full.revoked){
    					content += '<span class="label show success" translate="global.literals.revoked">Rebocat</span> ';
    					content += '<span class="_600" translate="global.literals.the">el</span> ';
    				}else if(isValid && !full.revoked){
    					content += '<span class="label show success" translate="global.literals.current">Vigent</span> ';
    					content += '<span class="_600" translate="global.literals.dateTo">date to</span> ';
    				}
    				content += '<span class="_600">{{'+full.endValidityPeriod+' | date:\'dd/MM/yyyy\'}}</span>';
                    return content;
    			}
                
				var columns = [];
				var tableOrderProperties = {};
				
				columns = [
						{ id: 'agent.id', width: '50px', title: $filter('translate')('global.literals.type'), render: receiverTypeRender},
    					{ id: 'agent.completeName', width: '200px', title: $filter('translate')('global.literals.name'), render: receiverNameRender},
    					{ id: 'agent.identificationDocument', width: '180px', title: $filter('translate')('global.thirds.literals.identificationdocument'), render: receiverIdDocumentRender},
    					{ id: 'endValidityPeriod', width: '150px', title: $filter('translate')('global.literals.state'), render: receiverStateRender},
    					{ id: 'actions', columnName: 'id', width: '50px', className: 'text-center', title: actionsTitle(), render: actionsRender, sortable: false }
    				];
				tableOrderProperties = {sortName: 'agent.completeName', sort: [[1,'asc']]};
    	        vm.receiverTableDefinition.id = 'tableTReceiverThird';
    			vm.receiverTableDefinition.origin = 'thirds';
    	        vm.receiverTableDefinition.objectType = 'ThirdRepresentation';
    			vm.receiverTableDefinition.objectIdToSelectOrigin ='thirds';
    			vm.receiverTableDefinition.objectIdToSelectType = 'ThirdRepresentation';
    	        vm.receiverTableDefinition.sortName = tableOrderProperties.sortName;
    	        vm.receiverTableDefinition.sort = tableOrderProperties.sort;
    			vm.receiverTableDefinition.columns = columns;
    	        vm.receiverTableDefinition.containerScope = $scope;
    			vm.receiverTableDefinition.useTableObjects = true;
    			vm.receiverTableDefinition.callOrigin = 'thirds';
    			vm.receiverTableDefinition.objectIdToSelect = angular.fromJson({agent:{id:vm.third.id}});
    			vm.receiverTableDefinition.objectIdToAdded = vm.third.id;
    			
    			vm.receiverBox = {
    			    boxTitle: 'global.thirds.literals.repr',
    			    permissions: {new: ['edit_third', 'new_third'], view: ['edit_third']},
    				tableDefinition: vm.receiverTableDefinition,
                    isEdit: true,
                    search: {placeholder: 'global.literals.placeholder_search_receiver', advanced: true, saveSearch: vm.addRepresentation},
                    new:{},
                    config: {agent: vm.third},
                    origin: 'thirds',
    				alerts: vm.thirdAlerts,
    				legend: undefined,
    				closeBox: vm.closedBox,
    				numberBox: ThirdFactory.numberReceiverBox
                }
			};
			
			this.$onInit = function() {
				vm.languageColumn = Language.getActiveColumn();
				vm.contactBox = {};
	    		try{
					generateReceiverBox();	
					$scope.$on('annexaBoxThirdReceiverTable', function(event, args) {
			            if(vm.receiverTableDefinition && vm.receiverTableDefinition.reloadInternalData) {
							vm.receiverTableDefinition.reloadInternalData(true, true);	
						}
			            ThirdFactory.calculateCounters('thirdReceivers');
			        });
				}catch(error){
					 console.error(error);
				}
			}
    	}],
        bindings: {
      	  third:'=',
      	  closedBox:'=',
    	  thirdAlerts:'='
        }
      })
      .component('annexaBoxReceiverPaginated', {
        templateUrl: './components/thirds/annexa-box-object-third-receiver/annexa-box-receiver-paginated.html',
        controller:['AnnexaObjectBoxFactory', 'Language', '$rootScope', 'RestService', 'GlobalDataFactory', '$filter', 'AnnexaFormlyFactory', '$scope', '$timeout', '$q', 'globalModals', 'AnnexaEntityFactory', 'ModalService', function (AnnexaObjectBoxFactory, Language, $rootScope, RestService, GlobalDataFactory, $filter, AnnexaFormlyFactory, $scope, $timeout, $q, globalModals, AnnexaEntityFactory, ModalService) {
            var vm = this;
            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASReceivers') {
                    var addedObjects = [];
                    var id = undefined;
                    _.forEach(args.selected, function (item) {
                		if(id){
                    		id =id+','+item.id;
                    	}else{
                    		id = item.id;
                    	}
                    	addedObjects.push(item);
                    });
                        
                    if(addedObjects && addedObjects.length > 0){
                    	vm.box.search.searchedValue = { id: id, object: addedObjects };
                        vm.box.search.completeAdd();
                    }
                    args.modal.close();
                }else if(args.id == 'modalASExternalReceivers') {
                    _.forEach(args.selected, function (item) {
                    	if (item && item.identificationReferences) {
                    		ThirdFactory.getPersonByDboid(item.identificationReferences[0].identificationReference).then(function (data) {
                    			vm.box.new.newFunction(JSOG.decode(data[0]));
                    		}).catch(function (data) {
                    			//Empty
                    		});
                    	} else {
                    		vm.box.new.newFunction(item);
                    	}
                    });
                    args.modal.close();
                } 
            });
            this.$onInit = function () {
				if(vm.box && vm.box.tableDefinition){
					vm.thirdIdentificationDocumentTypes = [
						{id: "DNI", language1:"DNI", language2:"DNI", language3:"DNI"}, 
						{id: "CIF", language1:"CIF", language2:"CIF", language3:"CIF"}, 
						{id: "NIE", language1:"NIE", language2:"NIE", language3:"NIE"}, 
						{id: "NIF", language1:"NIF", language2:"NIF", language3:"NIF"}, 
						{id: "PASSPORT", language1:$filter('translate')('global.thirds.literals.passport'), language2:$filter('translate')('global.thirds.literals.passport'), language3:$filter('translate')('global.thirds.literals.passport')}, 
						{id: "OTHER", language1:$filter('translate')('global.literals.other'), language2:$filter('translate')('global.literals.other'), language3:$filter('translate')('global.literals.other')}
					];
			        vm.thirdTypes = [
						{id: "PHISICAL", language1:$filter('translate')('global.thirds.literals.phisical'), language2:$filter('translate')('global.thirds.literals.phisical'), language3:$filter('translate')('global.thirds.literals.phisical')}, 
						{id: "LEGAL", language1:$filter('translate')('global.thirds.literals.legal'), language2:$filter('translate')('global.thirds.literals.legal'), language3:$filter('translate')('global.thirds.literals.legal')}, 
						{id: "PERSONWITHOUTLEGALPERSONALITY", language1:$filter('translate')('global.thirds.literals.personwithoutlegalpersonality'), language2:$filter('translate')('global.thirds.literals.personwithoutlegalpersonality'), language3:$filter('translate')('global.thirds.literals.personwithoutlegalpersonality')}
						
					];
			        
					vm.thirdModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.thirds'").toArray();
	                
	            	if(!vm.boxTitle) {
	                    vm.boxTitle = 'global.thirds.literals.repr';
	            	}
	            	
	            	if(vm.box.search) {
	                    vm.box.search.origin = 'thirds';
	                    vm.box.search.type = 'Third';

	                    vm.box.search.searchFunc = function(val) {
							var additionalFilter = vm.box.search.additionalFilter ? vm.box.search.additionalFilter : {};
							if(vm.box.config && vm.box.config.agent){
								additionalFilter.entityId = vm.box.config.agent.id;
	                        } 
                            additionalFilter.loggedUser = $rootScope.LoggedUser.id;
	                        additionalFilter.paginated = true;
							additionalFilter.paginatedObject = vm.box.tableDefinition.objectIdToSelectType;
	                        additionalFilter.paginatedObjectId = vm.box.tableDefinition.objectIdToAdded;
	                        additionalFilter.langColumn = Language.getActiveColumn();
	                        additionalFilter.searchBox = true;
							additionalFilter.active = true;
							var filter = {"name":val.val};
	                        
							return RestService.filterData(vm.box.search.origin, vm.box.search.type, filter, additionalFilter, 0, 10, '', 3, 'box')
	                            .then(function (data) {
	                                var response = [];
	
	                                _.forEach(data.data.content, function(value, key) {
	                                    response.push({
	                                        id: value.id,
	                                        object: JSOG.decode(value),
	                                        value: vm.box.search.processSearch(JSOG.decode(value))
	                                    });
	                                });
	                                response = new SortedArray(response, 'value').sort();
	                                return response;
	                            });
	                    }
	                    vm.box.search.processSearch = function(object) {
	                        var value = object.completeName + '(' + object.identificationDocument+')';
	                        return value;
	                    }
	
	                    if(!vm.box.search.completeAdd) {
	                        vm.box.search.completeAdd = function() {
	                        	var newContent = [];
	                        	if(vm.box.search.searchedValue && vm.box.search.searchedValue.object && Array.isArray(vm.box.search.searchedValue.object)){
	                        		_.forEach(vm.box.search.searchedValue.object, function(obj){
	                        			newContent.push(JSOG.decode(obj));
	                        		});
	                        	}else{
	                        		newContent.push(JSOG.decode(vm.box.search.searchedValue.object));
	                        	}
	  
	                    		vm.box.search.searchedValue = { id: -1, value: '' };
	                            if(vm.box.search.saveSearch ) {
	                                vm.box.search.saveSearch(newContent);
	                            }
	                        }
	                    }
	
	                    vm.box.search.advancedSearch = function() {
							var filter = [];	
							filter.push({ id: 'identificationDocument', type: 'text', order: 0, label: 'global.literals.identifier'});
							filter.push({ id: 'name', type: 'text', order: 1, label: 'global.literals.name'});
							filter.push({ id: 'identificationReference', type: 'text', order: 2, label: 'global.thirds.literals.identificationReference'});
							filter.push({ id: 'onlyActive', type: 'checkbox', order: 3, label: 'global.thirds.literals.onlyActive', callAux: true, model: true});
							filter.push({ id: 'city', type: 'text', order: 4, label: 'global.literals.city', callAux: true, userChoosen: true});
							filter.push({ id: 'mail', type: 'text', order: 5, label: 'global.profile.email', callAux: true, userChoosen: true});
							filter.push({ id: 'phone', type: 'text', order: 6, label: 'global.profile.phone', callAux: true, userChoosen: true});
							filter.push({ id: 'identificationDocumentType', type: 'select', order: 7, label: 'global.thirds.literals.identificationdocumenttype', dataType: 'LOCAL', data: vm.thirdIdentificationDocumentTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: true});
							filter.push({ id: 'thirdType', type: 'select', order: 8, label: 'global.literals.type', dataType: 'LOCAL', data: vm.thirdTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: true});
							filter.push({ id: 'dataThird', type: 'dateRange', order: 9, label: 'global.thirds.literals.dateCreated', callAux: true, userChoosen: true });
						
							var addressRender = function (data, type, full, meta) {
								var content = '';
								if(full){
		                            var thirdAddress = new ThirdAddress(full, Language.getActiveColumn(), undefined, $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value);
		                            content = thirdAddress.getDefaultAddressHtml();
								}
								return content;
							}

							var thirdTypeColumn = new IconFilterColumn($rootScope, 'filterData', 'thirdType', [
	                            new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
	                            new IconSearchOption('PHISICAL','PHISICAL', 'fa-user fa-lg ico-third', 'fa-user fa-fw', 'global.thirds.literals.phisical'),
	                            new IconSearchOption('LEGAL','LEGAL', 'fa-bank fa-lg ico-third', 'fa-bank fa-fw', 'global.thirds.literals.legal'),
	                            new IconSearchOption('PERSONWITHOUTLEGALPERSONALITY','PERSONWITHOUTLEGALPERSONALITY', 'fa-puzzle-piece fa-lg ico-third', 'fa-puzzle-piece fa-fw', 'global.thirds.literals.personwithoutlegalpersonality')
	                        ], 'tableThirds');

                            var advancedSearchModal = {
	                            title: 'global.literals.advanced_search_receivers',
	                            size: 'modal-lg',
	                            filter: filter,
	                            tableDefinition: {
	                                id: 'tableAdvancedSearchThirds',
	                                origin: 'thirds',
	                                objectType: 'Third',
	                                sortName: 'completeName',
	                                sort: [[3, 'asc']],
	                                columns: [
	                                    { id: 'thirdType', width: '150px', title: $filter('translate')('global.literals.type'), render:thirdTypeColumn.getRender},
	                                    { id: 'identificationDocument', width: '300px', title:$filter('translate')('global.literals.identifier') },
	                                    { id: 'completeName', width: '300px', title: $filter('translate')('global.thirds.literals.datatable.col2')},
	                                    { id: 'addresses', title: $filter('translate')('global.thirds.literals.datatable.col4'), render:addressRender }
	                                ],
	                                noLoading: true,
	                                skipFirstSearch: true,
	                                callAuxToAdd: {paginated:true, paginatedObject:vm.box.tableDefinition.objectIdToSelectType, paginatedObjectId:vm.box.tableDefinition.objectIdToAdded}
	                            }
	                        }
	                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASReceivers', advancedSearchModal);
	                    }
	               }
	            	vm.box.search.advancedExternalSearch = function() {
						var filter = [];	
						filter.push({ id: 'identificationDocument', type: 'text', order: 0, label: 'global.literals.identifier'});
						filter.push({ id: 'completeName', type: 'text', order: 1, label: 'global.literals.name'});
						filter.push({ id: 'identificationReference', type: 'text', order: 2, label: 'global.thirds.literals.identificationReference'});
					
						var addressRender = function (data, type, full, meta) {
							var content = '';
							if(full){
	                            var thirdAddress = new ThirdAddress(full, Language.getActiveColumn(), undefined, $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value);
	                            content = thirdAddress.getDefaultAddressHtml();
							}
							return content;
						}

						var thirdTypeColumn = new IconFilterColumn($rootScope, 'filterData', 'thirdType', [
                            new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
                            new IconSearchOption('PHISICAL','PHISICAL', 'fa-user fa-lg ico-third', 'fa-user fa-fw', 'global.thirds.literals.phisical'),
                            new IconSearchOption('LEGAL','LEGAL', 'fa-bank fa-lg ico-third', 'fa-bank fa-fw', 'global.thirds.literals.legal'),
                            new IconSearchOption('PERSONWITHOUTLEGALPERSONALITY','PERSONWITHOUTLEGALPERSONALITY', 'fa-puzzle-piece fa-lg ico-third', 'fa-puzzle-piece fa-fw', 'global.thirds.literals.personwithoutlegalpersonality')
                        ], 'tableThirds');

                        var advancedSearchModal = {
                            title: (($rootScope.app.configuration.conectaNucli_config && $rootScope.app.configuration.conectaNucli_config.title_external_search) ? $rootScope.app.configuration.conectaNucli_config.title_external_search : 'global.literals.advanced_external_search'),
                            size: 'modal-lg',
                            filter: filter,
                            tableDefinition: {
                                id: 'tableAdvancedExternalSearchThirds',
                                origin: 'thirds',
                                objectType: 'Third',
                                sortName: 'completeName',
                                sort: [[3, 'asc']],
                                columns: [
                                    { id: 'thirdType', title: $filter('translate')('global.literals.type'), render:thirdTypeColumn.getRender},
                                    { id: 'identificationDocument', title:$filter('translate')('global.literals.identifier') },
                                    { id: 'completeName', width: '25%', column: new ThirdColumn($filter, 'global.thirds.literals.datatable.col2', true) },
                                    { id: 'addresses', title: $filter('translate')('global.thirds.literals.defaultAddress'), render:addressRender }
                                ],
                                noLoading: true,
                                skipFirstSearch: true,
                                callAuxToAdd: {externalSearch:true, paginated:true, filterOrigin:"dossierAdvancedView"}
                            }
                        }
                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASExternalReceivers', advancedSearchModal);
                    }
                }
				if(vm.box.new) {
                    vm.box.new.newFunction = function (model) {
	                    var modal = angular.copy(globalModals.receiverNew);
	                    modal.title = 'global.literals.new_receiver_modal_title';
	                    
						//region Això s'haurà de canviar quan arreglem tercers
	                    var proccesNew = function(item, form) {
	                        vm.box.search.searchedValue = { id: item.third.id, object: item.third }
	                        $rootScope.$broadcast('annexaObjectBoxSearchAdd');
	                        form.close();
	                    }
	                    if (model) {
	                    	modal.annexaFormly.model = model;
	                    }
	                    var entity = new AnnexaEntity(new Date().getTime() + '', 'modal', AnnexaEntityFactory.getThirdBoxNewForm(modal, proccesNew), AnnexaEntityFactory.getThirdBoxes());
	                    if (model) {
	                    	if (model.identificationReferences) {
		                    	var modelIR = [];
    							_.forEach(model.identificationReferences, function(val) {
    								var identificationReference = { identificationReference: val.identificationReference, identificationReferenceType: val.identificationReferenceType }
    								modelIR.push(identificationReference);
    							});
		                    	entity.boxes[0].boxDefinition.content = modelIR;
	                    	}
		                    if (model.addresses) {
		                    	entity.boxes[1].boxDefinition.content = model.addresses;
		                    }
	                    }
	                    modal.annexaFormly = entity.form;
	                    modal.boxes = entity.boxes;
	                    modal.submitModal = modal.annexaFormly.onSubmit;
	                    modal.alerts = [];
	                    entity.modal = modal;
	                    ModalService.showModal({
	                        template: '<annexa-entity id="' + entity.id + '" entity-definition="modalObj" class="modal black-overlay" data-backdrop="false"/>',
	                        controller: 'AnnexaFormlyModalController',
	                        modalObj: entity
	                    }).then(function(modalThen) {
	                        modal.modal = modalThen;
	                        modal.close = modalThen.element.scope().close;
	                        modal.annexaFormly.close = modalThen.element.scope().close;
	                        modalThen.element.modal();
	                        angular.element(".modal-title").focus();
	                    });
	                    //endregion
                    }
					if(!vm.box.new.newFunc) {
		                vm.box.new.newFunc = vm.box.new.newFunction;
		                
						if ($rootScope.app.configuration.conectaNucli_config && $rootScope.app.configuration.conectaNucli_config.active) {
							vm.box.new.newFunc = vm.box.search.advancedExternalSearch;
						}
					}
				}
            }
        }],
        bindings: {
            box: '='
        }
    });