angular
    .module('annexaApp')
    .component('annexaBoxObjectThirdsContact',{
    	templateUrl: './components/thirds/annexa-box-object-third-contact/annexa-box-object-third-contact.html',
    	controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'AnnexaFormlyFactory', 'TerritoryFactory', 'AnnexaPermissionsFactory', 'globalModals', 'DialogsFactory', 'ThirdFactory', '$http', 'RestService', 'TableFilter','DTColumnBuilder', 'AnnexaModalFactory', 'dialogs', 'ErrorFactory',
    		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, AnnexaFormlyFactory, TerritoryFactory, AnnexaPermissionsFactory, globalModals, DialogsFactory, ThirdFactory, $http, RestService, TableFilter, DTColumnBuilder, AnnexaModalFactory, dialogs, ErrorFactory) {
    		var vm = this;
    		vm.languageColumn = undefined;
			vm.contactBox = undefined;
			vm.canViewDeletedAddresses = AnnexaPermissionsFactory.havePermission('view_address_disabled');
			
			vm.deleteContact = function(contactId){
				var titleDeleteMessage = '';
	        	var confirmDeleteMessage = '';
	        	
	        	titleDeleteMessage = 'global.literals.delete';
	        	confirmDeleteMessage = 'global.literals.deleteAddressBox';
	        	
	        	DialogsFactory.confirm(titleDeleteMessage, confirmDeleteMessage)
	                .then(function (data) {
	                	ThirdFactory.deleteThirdAddress(contactId, vm.third.id).then(function(data) {
	                  	  if(vm.contactTableDefinition && vm.contactTableDefinition.reloadInternalData) {
	      						vm.contactTableDefinition.reloadInternalData(true, true);
	      						$rootScope.$broadcast('annexaBoxThirdConsentTable', { reload:true });
	      						ThirdFactory.calculateCounters('thirdContacts');
	      					}
	                    }).catch(function(error) {
	                    	if(error){
				      			vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
				      		}else{
				      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
				      		}
	                    });
	                }).catch(function (data) {
	                    //Empty
	            });
				
			};
			vm.editContact = function(contact){
				var addressLanguage = 'address';
                if (!$rootScope.app.configuration.show_long_address_language || !$rootScope.app.configuration.show_long_address_language.value)
                	addressLanguage = 'shortAddress';

                var editedAddress = angular.copy(contact);
                if(editedAddress && editedAddress.addressType == 'POSTAL' && editedAddress.address && !editedAddress.address.territory){
                	var preCloseTerritoryModalFunction = function(address){
                		if(vm.contactTableDefinition && vm.contactTableDefinition.reloadInternalData) {
      						vm.contactTableDefinition.reloadInternalData(true, true);	
      						ThirdFactory.calculateCounters('thirdContacts');
      					}
            		}
                	if(editedAddress.address.id){
                    	RestService.findOne('Address', editedAddress.address.id).then(function (data) {
                    		TerritoryFactory.newAddress(JSOG.decode(data.data), preCloseTerritoryModalFunction, false);
                        }).catch(function (error) {
                        	if(error){
    			      			vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
    			      		}else{
    			      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
    			      		}
                        });
                	}else{
                		TerritoryFactory.newAddress(editedAddress.address, preCloseTerritoryModalFunction, false);
                	}
                }else{
                	var openModalNewContact = function() {
                		var modal = angular.copy(globalModals.contactsAdd);
                    	modal.annexaFormly.fields[0].fieldGroup[7].templateOptions.search = function(val) {
                    		var addressTypeSelected = undefined;
                    		if (modal.annexaFormly.model.modal_body.addressTypeTerritory && modal.annexaFormly.model.modal_body.territory_) {
                    			if(modal.annexaFormly.model.modal_body.addressTypeTerritory.id) {
                        			addressTypeSelected = {id:modal.annexaFormly.model.modal_body.addressTypeTerritory.id};
                    			} else {
                        			addressTypeSelected = {id:modal.annexaFormly.model.modal_body.addressTypeTerritory};
                    			}
                    		}
                    		var filter = {
                    				addresslanguage1:  val.val, 
                    				addresslanguage2:  val.val, 
                    				addresslanguage3:  val.val,
                    				addressType: addressTypeSelected
                    		};
                    		
                    		var additional_filter = { };
                    		return RestService.filterData('territory', 'Address', filter, additional_filter, 0, 10, '', 3)
                    		.then(function(data) {
                    			var response = [];
                    			
                    			_.forEach(data.data.content, function (value) {
                    				response.push({ id: value.id, object: JSOG.encode(value), value: value[addressLanguage+Language.getActiveColumn()] });
                    			});
                    			
                    			response = new SortedArray(response, 'value').sort();
                    			
                    			return response;
                    		});
                    	};
                    	modal.annexaFormly.fields[0].fieldGroup[9].templateOptions.executeFunction = function() {
                    		var preCloseFunction = function(address){
                    			if(modal.annexaFormly.model.modal_body.territory_){
                    				if(address && address.id){
                    					modal.annexaFormly.model.modal_body.address = {id:address.id, value: address[addressLanguage+Language.getActiveColumn()], object:address};
                    				}
                    			}else{
                    				if(address){
                    					modal.annexaFormly.model.modal_body.addressThirdAux = address;
                    					modal.annexaFormly.model.modal_body.addressThird = address[addressLanguage+Language.getActiveColumn()];
                    				}
                    			}
                    		}
                    		TerritoryFactory.newAddress(undefined, preCloseFunction, modal.annexaFormly.model.modal_body.territory_);
                    	};

                		var addressForm = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'address'");
                        if(addressForm) {
                        	addressForm.templateOptions.advancedSearch = function() {
                                var streetTypes = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.streetTypes));
        						var countries = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.countries));
        						var addressTypes = angular.copy(GlobalDataFactory.addressTypes)
        						
        						var tfilterDossier  = new TableFilter();
        						tfilterDossier.addElement('addressType', 'select', 0, 'global.literals.addressType', 3, HelperService.addAllSelect(addressTypes, undefined, Language.getActiveColumn()), true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(false);
        						tfilterDossier.addElement('streetType', 'select', 1, 'global.thirds.literals.streetType', 3, HelperService.addAllSelect(streetTypes, undefined, Language.getActiveColumn()), true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(false);
        						tfilterDossier.addElement('street', 'text', 2, 'global.thirds.literals.streetName').setFilterCallAux(true);
        						tfilterDossier.addElement('numberAddress', 'text', 3, 'global.thirds.literals.number').setFilterCallAux(true);
        						tfilterDossier.addElement('country', 'select', 4, 'global.thirds.literals.country', 3, HelperService.addAllSelect(countries, undefined, Language.getActiveColumn()), true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(false).setUserChosenFilter(true);
        						tfilterDossier.addElement('state', 'text', 5, 'global.thirds.literals.state').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('city', 'text', 6, 'global.literals.city').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('postalCode', 'text', 7, 'global.thirds.literals.postalCode').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('pseudoVia', 'text', 8, 'global.thirds.literals.pseudoVia').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('numberAddress2', 'text', 9, 'global.thirds.literals.number2').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('bis', 'text', 10, 'global.thirds.literals.bis').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('bis2', 'text', 11, 'global.thirds.literals.bis2').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('kilometer', 'text', 12, 'global.thirds.literals.kilometer').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('block', 'text', 13, 'global.thirds.literals.block').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('portal', 'text', 14, 'global.thirds.literals.portal').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('stair', 'text', 15, 'global.thirds.literals.stair').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('floor', 'text', 16, 'global.thirds.literals.floor').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('door', 'text', 17, 'global.thirds.literals.door').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('cadastralReference', 'text', 18, 'global.territory.list.cadastral_reference').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('coordinateX', 'text', 19, 'global.territory.list.coordinateX').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('coordinateY', 'text', 20, 'global.territory.list.coordinateY').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.addElement('address', 'text', 21, 'global.territory.list.address').setFilterCallAux(true).setUserChosenFilter(true);
        						tfilterDossier.loadLocalData([],undefined, undefined, Language.getActiveColumn());
        						
        						var tdDefDossier    = new TableData('territory', 'Address', './api/general/filterdata').setSortName(addressLanguage).setSort([[1, 'asc']]).setSkipFirstSearch(true);
        						
        						var addressTypeColumn = new DatabaseTranslatedParentColumn($filter, 'global.literals.addressType', Language.getActiveColumn());
        						var tabledataColumnsDossier = [
                                    DTColumnBuilder.newColumn(null).renderWith(function(data, type, full, meta){
                                        return '<input type="radio" id="'+full.id+'" name ="selected" data-ng-model="radioSelected" data-ng-value="'+ full.id +'" aria-label="{{ \'global.literals.select\' | translate }}">';
                                    }).notSortable(),
                                    DTColumnBuilder.newColumn(addressLanguage+Language.getActiveColumn()).withTitle($filter('translate')('global.territory.list.address')),
                                    DTColumnBuilder.newColumn('addressType').withTitle(addressTypeColumn.getTitle()).renderWith(addressTypeColumn.getRender)
                                ];
        						
        						var advancedModalDefinition = new BoxAdvancedSearchModalDefinition('modalASAddresses', 'global.literals.advanced_search_registerEntry', tfilterDossier, tdDefDossier, tabledataColumnsDossier, undefined, undefined, addressForm.templateOptions.advancedSearchAdd, modal).changeSize('modal-lg');
                                advancedModalDefinition.added = [];
                                advancedModalDefinition.isAdvanced = true;
                                advancedModalDefinition.active = 1;
                                advancedModalDefinition.language = Language.getActiveColumn();
                                advancedModalDefinition.addressLanguage = addressLanguage;

                                AnnexaModalFactory.showBoxAdvancedFilter(advancedModalDefinition);
                        	}
                        }
                    	var completedEdit = function(modal){
                    		var addressAux = angular.copy(contact);
							if(editedAddress.addressType == 'TELEMATIC_MOBILEPHONE'){
								addressAux.telematicValue = modal.annexaFormly.model.modal_body.contactPhone;
								addressAux.phoneInternationalPrefix = modal.annexaFormly.model.modal_body.contactPrefix;
                    		}
                    		if(editedAddress.addressType == 'TELEMATIC_EMAIL') {
                    			addressAux.telematicValue = modal.annexaFormly.model.modal_body.contactEmail;
                    		}
                    		if(editedAddress.addressType == 'POSTAL_CODE') {
                    			addressAux.telematicValue = modal.annexaFormly.model.modal_body.postalCode;
                    		}
                    		
                    		if(editedAddress.addressType == 'POSTAL') {
                    			if(modal.annexaFormly.model.modal_body.territory_){
                    				addressAux.address = {id:modal.annexaFormly.model.modal_body.address.id};
                    			}else{
                    				//TODO:addressThirdType
                    			}
                    		}
                    		addressAux.observations = modal.annexaFormly.model.modal_body.observations;
                    		ThirdFactory.updateThirdAddress(addressAux).then(function(data){
                    			if(vm.contactTableDefinition && vm.contactTableDefinition.reloadInternalData) {
                    				modal.close();
              						vm.contactTableDefinition.reloadInternalData(true, true);
              						$rootScope.$broadcast('annexaBoxThirdConsentTable', { reload:true });
              						ThirdFactory.calculateCounters('thirdContacts');
              					}
                    		}).catch(function (error) {
                    			if(error){
        			      			vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
        			      		}else{
        			      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
        			      		}
                    		});
                    	};
                    	
                    	if(editedAddress) {
                    		if(editedAddress && editedAddress.address && editedAddress.address.territory){
                    			editedAddress.editExitAddress = true;
                    			editedAddress.territory_ = true;
                    			if(editedAddress.address && editedAddress.address.id){
                    				editedAddress.addressTypeTerritory = editedAddress.address.addressType;
                    				editedAddress.address = {id:editedAddress.address.id, value: editedAddress.address[addressLanguage+Language.getActiveColumn()], object:editedAddress.address};
                    			}
                    		}else if(editedAddress && editedAddress.address ){
                    			editedAddress.editExitAddress = true;
                    			editedAddress.territory_ = false;
                    			editedAddress.addressThird = editedAddress.address[addressLanguage+Language.getActiveColumn()];
                    			editedAddress.addressThirdAux = editedAddress.address;
                    		}
                    		var showDefaultByTypeMobile = false;
                    		var defaults;
                    		defaults = $linq($scope.tableObjects).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_MOBILEPHONE\'').toArray();
                    		if(defaults && defaults.length > 0){
                    			showDefaultByTypeMobile = true;
                    		}
                    		var showDefaultByTypeEmail = false;
                    		defaults = $linq($scope.tableObjects).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_EMAIL\'').toArray();
                    		if(defaults && defaults.length > 0){
                    			showDefaultByTypeEmail = true;
                    		}
                    		var showDefaultByTypePostalCode = false;
                    		defaults = $linq($scope.tableObjects).where('x => x.defaultByType == true && x.addressType == \'POSTAL_CODE\'').toArray();
                    		if(defaults && defaults.length > 0){
                    			showDefaultByTypePostalCode = true;
                    		}
                    		var showDefaultByTypePostal = false;
                    		defaults = $linq($scope.tableObjects).where('x => x.defaultByType == true && x.addressType == \'POSTAL\'').toArray();
                    		if(defaults && defaults.length > 0){
                    			showDefaultByTypePostal = true;
                    		}
                    		var showDefault = true;
                    		defaults = $linq($scope.tableObjects).where('x => x.default == true').toArray();
                    		if(defaults && defaults.length > 0){
                    			showDefault = false;
                    		}
                    		
                    		modal.annexaFormly.model = {};
                    		modal.annexaFormly.model.modal_body = editedAddress;
                    		modal.annexaFormly.model.modal_body.addressType = editedAddress.addressType;
                    		modal.annexaFormly.model.modal_body.showDefaultByTypeMobile = showDefaultByTypeMobile;
                    		modal.annexaFormly.model.modal_body.showDefaultByTypeEmail = showDefaultByTypeEmail;
                    		modal.annexaFormly.model.modal_body.showDefaultByTypePostal = showDefaultByTypePostal;
                    		modal.annexaFormly.model.modal_body.showDefaultByTypePostalCode = showDefaultByTypePostalCode;
                    		modal.annexaFormly.model.modal_body.showDefault = showDefault;
                    		if(editedAddress.addressType == 'TELEMATIC_MOBILEPHONE'){
                    			modal.annexaFormly.model.modal_body.contactPhone = editedAddress.telematicValue;
                    			modal.annexaFormly.model.modal_body.contactPrefix = editedAddress.phoneInternationalPrefix;
                    		}
                    		
                    		if(editedAddress.addressType == 'TELEMATIC_EMAIL'){
                    			modal.annexaFormly.model.modal_body.contactEmail = editedAddress.telematicValue;
                    		}
                    		if(editedAddress.addressType == 'POSTAL_CODE'){
                    			modal.annexaFormly.model.modal_body.postalCode = editedAddress.telematicValue;
                    		}
                    		
                    		modal.extra = this;
                    		AnnexaFormlyFactory.showModal("modalNewContact", modal, completedEdit, false);
                    	}else {
                    		var err = dialogs.error(
                    				$filter('translate')('DIALOGS_ERROR_MSG'),
                    				$filter('translate')('global.errors.oneContactRequired'),
                    				{
                    					animation: true,
                    					backdrop: 'static',
                    					keyboard: true,
                    					size: ''
                    				}
                    		);
                    	}
                	}
                	
                	if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.default_town && $rootScope.app.configuration.default_town.value && !TerritoryFactory.defaultTown){
                		RestService.findOne('StreetCity',$rootScope.app.configuration.default_town.value).then(function(data) {
                			TerritoryFactory.defaultTown = JSOG.decode(data.data);
                			openModalNewContact();
                		}).catch(function(error) {
                			openModalNewContact();
                        });
            		} else {
            			openModalNewContact();
            		}
                	
                }
			};
			vm.addThirdContact = function(){
				var addressLanguage = 'address';
                if (!$rootScope.app.configuration.show_long_address_language || !$rootScope.app.configuration.show_long_address_language.value)
                	addressLanguage = 'shortAddress';
                
        		var modal = angular.copy(globalModals.contactsAdd);
        		var completeAddContact = function (){
        			var contact = {};

                    var contactAux = this.annexaFormly.model.modal_body;
                    if(contactAux.territory_){
                    	if(contactAux.address && contactAux.address.id){
                            var defaults = $linq($scope.tableObjects).where('x => x.defaultByType == true && x.addressType == \'POSTAL\'').toArray();
                            contact = { addressType:'POSTAL', key: CommonService.guid()};
                            if(contact.addressType == contactAux.addressType){
                                contact.default = contactAux.showDefault;
                            }
                            if(contactAux.showDefaultByTypePostal) {
                                contact.defaultByType = !contactAux.defaultPostal_defaultByType ? false : contactAux.defaultPostal_defaultByType;
                            }else{
                                contact.defaultByType = true;
                            }
                            if(contact.defaultByType && defaults && defaults.length > 0){
                                angular.forEach(defaults, function(value, key){value.defaultByType = false;});
                            }
                        	contact.address = {id: contactAux.address.id, addresslanguage1: contactAux.address.value, addresslanguage2: contactAux.address.value, addresslanguage3: contactAux.address.value, territory:true};
                        	
                            if (!$rootScope.app.configuration.show_long_address_language || !$rootScope.app.configuration.show_long_address_language.value) {
                            	contact.address.shortAddresslanguage1 = contact.address.shortAddresslanguage2 = contact.address.shortAddresslanguage3 = contactAux.address.value;
                            }
                        }
                    }else{
                    	if(contactAux.addressThirdAux){
                    		 var defaults = $linq($scope.tableObjects).where('x => x.defaultByType == true && x.addressType == \'POSTAL\'').toArray();
                             contact = { addressType:'POSTAL', key: CommonService.guid()};
                             if(contact.addressType == contactAux.addressType){
                                 contact.default = contactAux.showDefault;
                             }
                             if(contactAux.showDefaultByTypePostal) {
                                 contact.defaultByType = !contactAux.defaultPostal_defaultByType ? false : contactAux.defaultPostal_defaultByType;
                             }else{
                                 contact.defaultByType = true;
                             }
                             if(contact.defaultByType && defaults && defaults.length > 0){
                                 angular.forEach(defaults, function(value, key){value.defaultByType = false;});
                             }
                             contact.address = contactAux.addressThirdAux;
                             if(contact.address){
                            	 contact.address.createThirdAddress = true;
                             }
                    	}
                    }
                    if(contactAux.contactPhone){
                        var defaults = $linq($scope.tableObjects).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_MOBILEPHONE\'').toArray();
                        contact = { addressType:'TELEMATIC_MOBILEPHONE', key: CommonService.guid() };
                        if(contact.addressType == contactAux.addressType){
                            contact.default = contactAux.showDefault;
                        }
                        if(contactAux.showDefaultByTypeMobile) {
                            contact.defaultByType = !contactAux.defaultNumber_defaultByType ? false : contactAux.defaultNumber_defaultByType;
                        }else{
                            contact.defaultByType = true;
                        }
                        if(contact.defaultByType && defaults && defaults.length > 0){
                            angular.forEach(defaults, function(value, key){value.defaultByType = false;});
                        }
                        contact.phoneInternationalPrefix = contactAux.contactPrefix;
                        contact.telematicValue = contactAux.contactPhone;
                    }
                    if(contactAux.contactEmail){
                        var defaults = $linq($scope.tableObjects).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_EMAIL\'').toArray();
                        contact = { addressType:'TELEMATIC_EMAIL', key: CommonService.guid() };
                        if(contact.addressType == contactAux.addressType){
                            contact.default = contactAux.showDefault;
                        }
                        if(contactAux.showDefaultByTypeEmail) {
                            contact.defaultByType = !contactAux.defaultEmail_defaultByType ? false : contactAux.defaultEmail_defaultByType;
                        }else{
                            contact.defaultByType = true;
                        }
                        if(contact.defaultByType && defaults && defaults.length > 0){
                            angular.forEach(defaults, function(value, key){value.defaultByType = false;});
                        }
                        contact.telematicValue = contactAux.contactEmail;
                    }
                    if(contactAux.postalCode){
                        var defaults = $linq($scope.tableObjects).where('x => x.defaultByType == true && x.addressType == \'POSTAL_CODE\'').toArray();
                        contact = { addressType:'POSTAL_CODE', key: CommonService.guid() };
                        if(contact.addressType == contactAux.addressType){
                            contact.default = contactAux.showDefault;
                        }
                        if(contactAux.showDefaultByTypePostalCode) {
                            contact.defaultByType = !contactAux.defaultPostalCode_defaultByType ? false : contactAux.defaultPostalCode_defaultByType;
                        }else{
                            contact.defaultByType = true;
                        }
                        if(contact.defaultByType && defaults && defaults.length > 0){
                            angular.forEach(defaults, function(value, key){value.defaultByType = false;});
                        }
                        contact.telematicValue = contactAux.postalCode;
                    }
                    if(contact.default) {
                    	contact.default = $linq(GlobalDataFactory.notificationTypes).contains(contact.addressType, "(x, y) => x.addressType.toLowerCase() == y.toLowerCase()");
                    }
                    contact.observations = contactAux.observations;
                    
                    contact.third = {id: vm.third.id};
                    
                    ThirdFactory.addThirdAddress(contact).then(function(data){
            			if(vm.contactTableDefinition && vm.contactTableDefinition.reloadInternalData) {
            				modal.close();
      						vm.contactTableDefinition.reloadInternalData(true, true);	
      						$rootScope.$broadcast('annexaBoxThirdConsentTable', { reload:true });
      						ThirdFactory.calculateCounters('thirdContacts');
      					}
            			
            		}).catch(function (error) {
            			if(error){
			      			vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
			      		}else{
			      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
			      		}
            		});
                
        		};
                modal.annexaFormly.model = {};
                var showDefaultByTypeMobile = false;
                var defaults;
                defaults = $linq($scope.tableObjects).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_MOBILEPHONE\'').toArray();
                if(defaults && defaults.length > 0){
                    showDefaultByTypeMobile = true;
                }
                var showDefaultByTypeEmail = false;
                defaults = $linq($scope.tableObjects).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_EMAIL\'').toArray();
                if(defaults && defaults.length > 0){
                    showDefaultByTypeEmail = true;
                }
                var showDefaultByTypePostal = false;
                defaults = $linq($scope.tableObjects).where('x => x.defaultByType == true && x.addressType == \'POSTAL\'').toArray();
                if(defaults && defaults.length > 0){
                    showDefaultByTypePostal = true;
                }
                var showDefaultByTypePostalCode = false;
                defaults = $linq($scope.tableObjects).where('x => x.defaultByType == true && x.addressType == \'POSTAL_CODE\'').toArray();
                if(defaults && defaults.length > 0){
                	showDefaultByTypePostalCode = true;
                }
                var showDefault = true;
                defaults = $linq($scope.tableObjects).where('x => x.default == true').toArray();
                if(defaults && defaults.length > 0){
                    showDefault = false;
                }
                var canCreateNewAddress = TerritoryFactory.canCreateAddress();

                modal.annexaFormly.model.modal_body = {addressType:'POSTAL', hasStates:true, hasCities: true, hasStreetTypes: true, showDefaultByTypeMobile:showDefaultByTypeMobile, showDefaultByTypeEmail:showDefaultByTypeEmail, showDefaultByTypePostal:showDefaultByTypePostal, showDefaultByTypePostalCode:showDefaultByTypePostalCode, showDefault:showDefault, territory_:true, canCreateNewAddress:canCreateNewAddress};
                modal.annexaFormly.fields[0].fieldGroup[7].templateOptions.search = function(val) {
                    var filter = {
                		addresslanguage1:  val.val, 
                		addresslanguage2:  val.val, 
                		addresslanguage3:  val.val,
                		addressType:((modal.annexaFormly.model.modal_body.addressTypeTerritory && modal.annexaFormly.model.modal_body.territory_)?{id:modal.annexaFormly.model.modal_body.addressTypeTerritory}:undefined)
                    };
                    
                    var additional_filter = { };
                    return RestService.filterData('territory', 'Address', filter, additional_filter, 0, 10, '', 3).then(function(data) {
                        var response = [];
                        _.forEach(data.data.content, function (value) {
                        	response.push({ id: value.id, object: JSOG.encode(value), value: value[addressLanguage+Language.getActiveColumn()] });
                        });

                        response = new SortedArray(response, 'value').sort();
                        return response;
                    });
                };
                modal.annexaFormly.fields[0].fieldGroup[9].templateOptions.executeFunction = function() {
                	var preCloseFunction = function(address){
                		if(modal.annexaFormly.model.modal_body.territory_){
	                		if(address && address.id){
                				modal.annexaFormly.model.modal_body.address = {id:address.id, value: address[addressLanguage+Language.getActiveColumn()], object:address};
	                    	}
                		}else{
                			if(address){
	                			modal.annexaFormly.model.modal_body.addressThirdAux = address;
                				modal.annexaFormly.model.modal_body.addressThird = address[addressLanguage+Language.getActiveColumn()];
	                    	}
                		}
                	}
                    TerritoryFactory.newAddress(undefined, preCloseFunction, modal.annexaFormly.model.modal_body.territory_);
                };
                var addressForm = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'address'");
                if(addressForm) {
                	addressForm.templateOptions.advancedSearch = function() {
                        var streetTypes = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.streetTypes));
						var countries = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.countries));
						var addressTypes = angular.copy(GlobalDataFactory.addressTypes)
						
						var tfilterDossier  = new TableFilter();
						tfilterDossier.addElement('addressType', 'select', 0, 'global.literals.addressType', 3, HelperService.addAllSelect(addressTypes, undefined, Language.getActiveColumn()), true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(false);
						tfilterDossier.addElement('streetType', 'select', 1, 'global.thirds.literals.streetType', 3, HelperService.addAllSelect(streetTypes, undefined, Language.getActiveColumn()), true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(false);
						tfilterDossier.addElement('street', 'text', 2, 'global.thirds.literals.streetName').setFilterCallAux(true);
						tfilterDossier.addElement('numberAddress', 'text', 3, 'global.thirds.literals.number').setFilterCallAux(true);
						tfilterDossier.addElement('country', 'select', 4, 'global.thirds.literals.country', 3, HelperService.addAllSelect(countries, undefined, Language.getActiveColumn()), true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(false).setUserChosenFilter(true);
						tfilterDossier.addElement('state', 'text', 5, 'global.thirds.literals.state').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('city', 'text', 6, 'global.literals.city').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('postalCode', 'text', 7, 'global.thirds.literals.postalCode').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('pseudoVia', 'text', 8, 'global.thirds.literals.pseudoVia').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('numberAddress2', 'text', 9, 'global.thirds.literals.number2').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('bis', 'text', 10, 'global.thirds.literals.bis').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('bis2', 'text', 11, 'global.thirds.literals.bis2').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('kilometer', 'text', 12, 'global.thirds.literals.kilometer').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('block', 'text', 13, 'global.thirds.literals.block').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('portal', 'text', 14, 'global.thirds.literals.portal').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('stair', 'text', 15, 'global.thirds.literals.stair').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('floor', 'text', 16, 'global.thirds.literals.floor').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('door', 'text', 17, 'global.thirds.literals.door').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('cadastralReference', 'text', 18, 'global.territory.list.cadastral_reference').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('coordinateX', 'text', 19, 'global.territory.list.coordinateX').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('coordinateY', 'text', 20, 'global.territory.list.coordinateY').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('address', 'text', 21, 'global.territory.list.address').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.loadLocalData([],undefined, undefined, Language.getActiveColumn());
						
						var tdDefDossier    = new TableData('territory', 'Address', './api/general/filterdata').setSortName('address').setSort([[1, 'asc']]).setSkipFirstSearch(true);
						
						var addressTypeColumn = new DatabaseTranslatedParentColumn($filter, 'global.literals.addressType', Language.getActiveColumn());
						var tabledataColumnsDossier = [
                            DTColumnBuilder.newColumn(null).renderWith(function(data, type, full, meta){
                                return '<input type="radio" id="'+full.id+'" name ="selected" data-ng-model="radioSelected" data-ng-value="'+ full.id +'" aria-label="{{ \'global.literals.select\' | translate }}">';
                            }).notSortable(),
                            DTColumnBuilder.newColumn(addressLanguage+Language.getActiveColumn()).withTitle($filter('translate')('global.territory.list.address')),
                            DTColumnBuilder.newColumn('addressType').withTitle(addressTypeColumn.getTitle()).renderWith(addressTypeColumn.getRender)
                        ];
						
						var advancedModalDefinition = new BoxAdvancedSearchModalDefinition('modalASAddresses', 'global.literals.advanced_search_registerEntry', tfilterDossier, tdDefDossier, tabledataColumnsDossier, undefined, undefined, addressForm.templateOptions.advancedSearchAdd, modal).changeSize('modal-lg');
                        advancedModalDefinition.added = [];
                        advancedModalDefinition.isAdvanced = true;
                        advancedModalDefinition.active = 1;
                        advancedModalDefinition.language = Language.getActiveColumn();
						advancedModalDefinition.addressLanguage = addressLanguage;

                        AnnexaModalFactory.showBoxAdvancedFilter(advancedModalDefinition);
                	}
                }
                modal.extra = this;
                AnnexaFormlyFactory.showModal("modalNewContact", modal, completeAddContact, false);
			};

			var generateContactBox= function(){
				if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.default_town && $rootScope.app.configuration.default_town.value && !TerritoryFactory.defaultTown){
	                RestService.findOne('StreetCity',$rootScope.app.configuration.default_town.value).then(function(data) {
	                    TerritoryFactory.defaultTown = JSOG.decode(data.data);
	                }).catch(function(error) {
	                });
	            }
				if(!vm.contactTableDefinition){
    				vm.contactTableDefinition = {};
    			}
				vm.canEditThird = function(){
	                return AnnexaPermissionsFactory.haveSinglePermission('edit_third');
	            };
	            vm.setDefaultContact = function(idContact) {
		        	ThirdFactory.defaultAddress(idContact, vm.third.id).then(function(data){
						if(vm.contactTableDefinition && vm.contactTableDefinition.reloadInternalData){
							vm.contactTableDefinition.reloadInternalData(false, true);	
						}
						ThirdFactory.calculateCounters('thirdContacts');
//						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.principalThird = data;
                    }).catch(function (error) {
                    	if(error && error){
			      			vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
			      		}else{
			      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
			      		}
	                });
		        };
		        
				var contactRender = function(data, type, full, meta) {
					var thirdAddress = new ThirdAddress(undefined,Language.getActiveColumn(), undefined, $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value);
					full.canBeDefault = $linq(GlobalDataFactory.notificationTypes).contains(full.addressType, "(x, y) => x.addressType.toLowerCase() == y.toLowerCase()");
					var content = '';
					
					if(!full.removedDate){
						content += '<div class="row">';
						content += '    <div class="col-xs-11">';
						content += '        <div class="row m-b-xs _600">';
						content += '            <div class="col-xs-1">';
						if(full.addressType == 'TELEMATIC_EMAIL'){
							content += '<i title="{{\'global.thirds.addressTypes.TELEMATIC_EMAIL\' | translate}}" class="fa fa-envelope fa-fw"></i>';
						}else if(full.addressType == 'TELEMATIC_MOBILEPHONE'){
							content += '<i title="{{\'global.thirds.addressTypes.TELEMATIC_MOBILEPHONE\' | translate}}" class="fa fa-mobile fa-fw fa-mob-esp"></i>';
						}else if(full.addressType == 'POSTAL'){
							content += '<i title="{{\'global.thirds.addressTypes.POSTAL\' | translate}}" class="fa fa-map-marker fa-fw"></i>';
						}else if(full.addressType == 'POSTAL_CODE'){
							content += '<i title="{{\'global.thirds.addressTypes.POSTAL_CODE\' | translate}}" class="fa fa-map-marker fa-fw"></i>';
						}
						content += '            </div>';
						content += '            <div class="col-md-8 _600">'+thirdAddress.getAddressHtml(full, false)+'</div>';
						content += '            <div class="col-xs-1">';
						if(full.canBeDefault && full.default){
							content += '<button type="button" title="{{ \'global.literals.default_channel\' | translate}}" class="md-btn p-a-0 md-flat md-btn__star" ng-disabled="!$ctrl.canEditThird()" ng-click="$ctrl.setDefaultContact('+full.id+')">';
							content += '<span class="sr-only">{{ \'global.literals.default_channel\' | translate}}</span>';
							content += '<i class="fa fa-star text-warn" aria-hidden="true" ></i>';
							content += '</button>';
						}else if(full.canBeDefault){
							content += '<button type="button" title="{{ \'global.literals.mark_as_default_channel\' | translate}}" class="md-btn p-a-0 md-flat md-btn__star" ng-disabled="!$ctrl.canEditThird()" ng-click="$ctrl.setDefaultContact('+full.id+')">';
							content += '<span class="sr-only">{{ \'global.literals.mark_as_default_channel\' | translate}}</span>';
							content += '<i class="fa fa-star-o" aria-hidden="true" ></i>';
							content += '</button>';
						}else if(!full.canBeDefault && full.default){
							content += '<button type="button" title="{{ \'global.literals.default_channel\' | translate}}" class="md-btn p-a-0 md-flat md-btn__star" ng-disabled="true">';
							content += '<span class="sr-only">{{ \'global.literals.default_channel\' | translate}}</span>';
							content += '<i class="fa fa-star text-warn" aria-hidden="true" ></i>';
							content += '</button>';
						}else if(!full.canBeDefault){
							content += '<button type="button" title="{{ \'global.literals.mark_as_default_channel\' | translate}}" class="md-btn p-a-0 md-flat md-btn__star" ng-disabled="true">';
							content += '<span class="sr-only">{{ \'global.literals.mark_as_default_channel\' | translate}}</span>';
							content += '<i class="fa fa-star-o" aria-hidden="true" ></i>';
							content += '</button>';
						}
						content += '            </div>';
						content += '        </div>';
						content += '    </div>';
						content += '	<div>';
			            content += '    	<button class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.editContact(tableObjects[' + full.id + '])" permission permission-only="[\'edit_third\']"><i class="fa fa-edit" title="{{ \'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.edit\' | translate}}</span></button>';
			            content += '   		<button class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.deleteContact('+full.id+')"  title="{{ \'global.literals.remove\' | translate}}" permission permission-only="[\'edit_third\']"><i class="fa fa-remove" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.remove\' | translate}}</span></button>';
			            content += '	</div>';
			            content += '</div>';
					}else if(vm.canViewDeletedAddresses){
						content += '<div class="row p-y-sm">';
						content += '	<div class="col-xs-11">';
						content += '		<div class="row m-b-xs ">';
			            content += '			<div class="col-xs-1 text-grey">';
			            if(full.addressType == 'TELEMATIC_EMAIL'){
							content += '<i title="{{\'global.thirds.addressTypes.TELEMATIC_EMAIL\' | translate}}" class="fa fa-envelope fa-fw"></i>';
						}else if(full.addressType == 'TELEMATIC_MOBILEPHONE'){
							content += '<i title="{{\'global.thirds.addressTypes.TELEMATIC_MOBILEPHONE\' | translate}}" class="fa fa-mobile fa-fw fa-mob-esp"></i>';
						}else if(full.addressType == 'POSTAL'){
							content += '<i title="{{\'global.thirds.addressTypes.POSTAL\' | translate}}" class="fa fa-map-marker fa-fw"></i>';
						}else if(full.addressType == 'POSTAL_CODE'){
							content += '<i title="{{\'global.thirds.addressTypes.POSTAL_CODE\' | translate}}" class="fa fa-map-marker fa-fw"></i>';
						}
			            content += '</div>';
			            content += '<div class="col-md-10 _600 text-grey">'+thirdAddress.getAddressHtml(full, false)+'</div>';
			            content += '</div>';
			            content += '	</div>';
			            content += '	<div class="col-xs-1" permission permission-only="[\'edit_third\']">';
			            content += '	</div>';
			            content += '</div>';
					}
    				return content;
    			}
				
				var columns = [];
				var tableOrderProperties = {};
				
				if(vm.canViewDeletedAddresses){
					 columns = [
		    				{ id: 'removedDate', width: '95%', title: $filter('translate')('global.literals.name'), render: contactRender}
		    	        ];
		             tableOrderProperties = {sortName: 'removedDate', sort: [[0,'asc']]};
				}else{
					columns = [
	    					{ id: 'third.completeName', width: '95%', title: $filter('translate')('global.literals.name'), render: contactRender}
	    				];
					tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
				}
               
    	        vm.contactTableDefinition.id = 'tableTContactThird';
    			vm.contactTableDefinition.origin = 'thirds';
    	        vm.contactTableDefinition.objectType = 'ThirdAddress';
    			vm.contactTableDefinition.objectIdToSelectOrigin ='thirds';
    			vm.contactTableDefinition.objectIdToSelectType = 'ThirdAddress';
    	        vm.contactTableDefinition.sortName = tableOrderProperties.sortName;
    	        vm.contactTableDefinition.sort = tableOrderProperties.sort;
    			vm.contactTableDefinition.columns = columns;
    	        vm.contactTableDefinition.containerScope = $scope;
    			vm.contactTableDefinition.useTableObjects = true;
    			vm.contactTableDefinition.callOrigin = 'thirds';
    			vm.contactTableDefinition.objectIdToSelect = angular.fromJson({third:{id:vm.third.id}});
    			vm.contactTableDefinition.objectIdToAdded = vm.third.id;
    			
    			vm.contactBox = {
    			    boxTitle: 'global.thirds.literals.form.contactData',
    			    permissions: {new: ['edit_third']},
    				tableDefinition: vm.contactTableDefinition,
                    isEdit: true,
                    search: {},
                    new:{newFunc:vm.addThirdContact},
                    config: {third: vm.third},
                    origin: 'thirds',
    				alerts: vm.thirdAlerts,
    				legend: undefined,
    				closeBox: vm.closedBox,
    				numberBox: ThirdFactory.numberContactBox
                }
			};
			
			this.$onInit = function() {
				vm.languageColumn = Language.getActiveColumn();
				vm.contactBox = {};
	    		try{
					generateContactBox();	
					$scope.$on('annexaBoxThirdContactTable', function(event, args) {
			            if(vm.contactTableDefinition && vm.contactTableDefinition.reloadInternalData) {
							vm.contactTableDefinition.reloadInternalData(true, true);	
						}
			            ThirdFactory.calculateCounters('thirdContacts');
			        });
				}catch(error){
					 console.error(error);
				}
			}
			
    	}],
        bindings: {
      	  third:'=',
      	  closedBox:'=',
    	  thirdAlerts:'='
        }
      })
      .component('annexaBoxContactPaginated', {
        templateUrl: './components/thirds/annexa-box-object-third-contact/annexa-box-contact-paginated.html',
        controller:['AnnexaObjectBoxFactory', 'Language', '$rootScope', 'RestService', 'RegFactory', 'GlobalDataFactory', '$filter', 'AnnexaFormlyFactory', '$scope', '$timeout', '$q','TramNewFactory', function (AnnexaObjectBoxFactory, Language, $rootScope, RestService, RegFactory, GlobalDataFactory, $filter, AnnexaFormlyFactory, $scope, $timeout, $q, TramNewFactory) {
            var vm = this;
            this.$onInit = function () {
				if(vm.box && vm.box.tableDefinition){
					vm.thirdModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.thirds'").toArray();
	                
	            	if(!vm.boxTitle) {
	                    vm.boxTitle = 'global.thirds.literals.form.contactData';
	            	}
				}
            }
        }],
        bindings: {
            box: '='
        }
    });
