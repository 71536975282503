angular
    .module('annexaApp')
    .component('annexaBoxObjectThirdsDossier',{
        templateUrl: './components/thirds/annexa-box-object-third-dossier/annexa-box-object-third-dossier.html',
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 'TramFactory', 'ErrorFactory', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http, TramFactory, ErrorFactory) {
        	//Està basat en annexaBoxObjectDossierDossierDossiers
        	var vm = this;
			vm.languageColumn = undefined;
			vm.dossierBox = {};
			
			var generateDossierBox= function(){
				if(!vm.dossierTableDefinition){
					vm.dossierTableDefinition = {};
				}

				vm.deleteDossier = function(dossierThirdId) {
		        	var titleDeleteMessage = '';
		        	var confirmDeleteMessage = '';
		        	
		        	titleDeleteMessage = 'global.literals.delete';
		        	confirmDeleteMessage = 'global.literals.deleteDossierBox';
		        	
		        	DialogsFactory.confirm(titleDeleteMessage, confirmDeleteMessage)
		                .then(function (data) {
			                    if(vm.dossierBox && vm.dossierBox.isEdit) {
									TramNewFactory.deleteRelatedThird(vm.third.id, dossierThirdId).then(function (data) {
										if(vm.dossierTableDefinition && vm.dossierTableDefinition.reloadInternalData){
											vm.dossierTableDefinition.reloadInternalData(true, true);	
										}
										ThirdFactory.calculateCounters('thirdDossiers');
			                        }).catch(function (error) {
			                        	if(error){
			    			      			vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
			    			      		}else{
			    			      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
			    			      		}
			                        });
			                    }
		                }).catch(function (data) {
		                    //Empty
		            });
		        }
	            var relatedDossierProcedureRender = function (data, type, full, meta) {
					var content = '';
					if(data){
						if(full.dossier && full.dossier.id){
							content = '<span class=" _600">'+((full.dossier.procedure && full.dossier.procedure[vm.languageColumn])?full.dossier.procedure[vm.languageColumn]:'')+'</span>';
						}
					}
	                return content;
	            }
				var relatedDossierNumberRender = function (data, type, full, meta) {
					var content = '';
					if(full.dossier && full.dossier.id){
						if(full.dossier && full.dossier.dossierStatus && full.dossier.dossierStatus != 'ARCHIVED' && full.dossier.dossierStatus != 'REJECTED'  && full.dossier.dossierStatus != 'TRANSFER'){
							content = '<a class="text-primary _400 small-lg" href="" ui-sref="annexa.tram.pending.viewdossier({dossier: '+full.dossier.id+', dossierTransaction: 0})" target="_blank">'+full.dossier.dossierNumber+'</a>';
						}else{
							content = '<span class="text-primary _400 small-lg">'+full.dossier.dossierNumber+'</span>';
						}
					}
	                return content;
	            }
            
				var relatedDossierSubjectRender = function (data, type, full, meta) {
					var content = '';
					if(full.dossier && full.dossier.id){
						content = '<span class=" _600">'+((full.dossier.subject)?full.dossier.subject:'')+'</span>';
					}
	                return content;
	            }

				var relatedDossierStatusRender = function (data, type, full, meta) {
					var content = '';
	                if(full.dossier && full.dossier.id){
						if(full.dossier.closed){
							if(full.dossier.dossierStatus != 'CANCELLED'){
								content += '<span class="label label-xxs success" translate="global.literals.ended">Ended</span>';
							}else{
								content += '<span class="label label-xxs danger" translate="CANCELLED">Cancelled</span>';
							}
							content += '<span class="small text-success">{{dossier.dossier.endDate | date: \'dd/MM/yyyy HH:mm:ss\'}}</span>';
						}else{
							content += '<span class="label label-xxs" translate="global.literals.in_process">In proces</span>';
						}
					}
					return content;
	            }

				var actionsDossierRender = function (data, type, full, meta) {
					var content = '';
					if(AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', undefined)){
						content += '<button type="button"  class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.deleteDossier('+full.id+')" annexa-permission annexa-permission-only="[\'process_dossier\']">';
		                content += '<i class="fa fa-remove" title="{{ \'global.literals.remove\' | translate }}" aria-hidden="true"></i>';
		                content += '<span class="sr-only">{{ \'global.literals.remove\' | translate}}</span>';
		                content += '</button>';
					}
	                return content;
	            }
				var addDossierSave = function(dossier) {
					var addThirdToDosier = function(){
                		var self = this;
                		var newContent = {
                            third: {id:this.annexaFormly.model.modal_body.object.id},
                            interested: (( this.annexaFormly.model.modal_body.thirdType == 'interested')?true:false),
                            representative:  (( this.annexaFormly.model.modal_body.thirdType == 'representative')?true:false),
                            principal: false,
                            representated: ((this.annexaFormly.model.modal_body.representated && this.annexaFormly.model.modal_body.representated.id)?{id:this.annexaFormly.model.modal_body.representated.id}:undefined),
                            roleInterested: ((this.annexaFormly.model.modal_body.roleInterested && this.annexaFormly.model.modal_body.roleInterested.id)?{id:this.annexaFormly.model.modal_body.roleInterested.id}:undefined),
                            dossier: {id: dossierIds[0]},
                            additionalInformation: this.annexaFormly.model.modal_body.additionalInformation
                		};
                		TramFactory.createRelatedThird(dossierIds[0], newContent).then(function(data){
                			if(vm.dossierTableDefinition && vm.dossierTableDefinition.reloadInternalData) {
                				$rootScope.$broadcast('closeAnnexaFormModal', { id: 'modalSearchAdd' });
                				self.close();
        						vm.dossierTableDefinition.reloadInternalData(true, true);
								ThirdFactory.calculateCounters('thirdDossiers');
        					}
                        	
                		}).catch(function(error){
                			if(error){
    			      			vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
    			      		}else{
    			      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
    			      		}
                		});
                	}
                	var dossierIds = $linq(dossier).select("x => x.id").toArray();
                	TramFactory.getRelatedThirds(dossierIds[0]).then(function(data){
                    	var modal = angular.copy(globalModals.receiverAdd);
                        modal.size = 'modal-md';
                        modal.annexaFormly.model = {};
                        modal.annexaFormly.model.modal_body = { third: vm.third.id, interested: false, representative: false, object: angular.copy(vm.third) };
                        modal.annexaFormly.options = {};
                        modal.annexaFormly.options.formState = {readOnly: false, data: data};
                      
                        AnnexaFormlyFactory.showModal("modalSearchAdd", modal, addThirdToDosier, false);
                	}).catch(function(error){
                		if(error){
			      			vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
			      		}else{
			      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
			      		}
                	});
				}
					
				var actionsTitle = function () {
    	            return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
	            }

				var columnsDossier = [
					{ id: 'dossier.dossierNumber', width: '150px', title: $filter('translate')('global.literals.dossierNumber'), render:relatedDossierNumberRender},
					{ id: 'dossier.procedure', width: '350px', title: $filter('translate')('global.literals.procedure'), render:relatedDossierProcedureRender},
					{ id: 'dossier.subject', width: '250px', title: $filter('translate')('global.literals.subject'), render:relatedDossierSubjectRender},
	                { id: 'dossier.dossierStatus', width: '150px', title: $filter('translate')('global.literals.status'), render:relatedDossierStatusRender},
					{ id: 'actions', columnName: 'id', className: 'text-center', title: actionsTitle(), render: actionsDossierRender, sortable: false }
		        ];

				var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
		        vm.dossierTableDefinition.id = 'tableTDossiers';
				vm.dossierTableDefinition.origin = 'tram';
		        vm.dossierTableDefinition.objectType = 'DossierThird';
				vm.dossierTableDefinition.objectIdToSelectOrigin ='tram';
				vm.dossierTableDefinition.objectIdToSelectType = 'DossierThird';
		        vm.dossierTableDefinition.sortName = tableOrderProperties.sortName;
		        vm.dossierTableDefinition.sort = tableOrderProperties.sort;
				vm.dossierTableDefinition.columns = columnsDossier;
		        vm.dossierTableDefinition.containerScope = $scope;
				vm.dossierTableDefinition.useTableObjects = true;
				vm.dossierTableDefinition.callOrigin = 'thirds';
				vm.dossierTableDefinition.objectIdToSelect = angular.fromJson({third:{id:vm.third.id}});
				vm.dossierTableDefinition.objectIdToAdded = vm.third.id;
				
				vm.dossierBox = {
				    boxTitle: 'global.literals.related_dossiers',
					permissions: { view: ['process_dossier', 'view_dossier', 'see_all_dossier']},
					tableDefinition: vm.dossierTableDefinition,
                    isEdit: true,
                    search: { placeholder: 'global.literals.placeholder_search_dossiers', advanced: true, saveSearch: addDossierSave},
                    config: {third: vm.third},
                    origin: 'thirds',
					alerts: vm.thirdAlerts,
					legend: undefined,
					closeBox: vm.closedBox,
					numberBox: ThirdFactory.numberDossierBox
                }
				
			}

			this.$onInit = function() {
				vm.languageColumn = Language.getActiveColumn();
				vm.dossierBox = {};
        		try{
					generateDossierBox();
					$scope.$on('annexaBoxDossierDossiersReloadTable', function(event, args) {
			            if(vm.dossierTableDefinition && vm.dossierTableDefinition.reloadInternalData) {
							vm.dossierTableDefinition.reloadInternalData(true, true);	
						}
						ThirdFactory.calculateCounters('thirdDossiers');
			        });
				}catch(error){
					 console.error(error);
				}
			}
        }],
        bindings: {
        	third: '=',
        	closedBox:'=',
        	thirdAlerts:'='
        }
    });